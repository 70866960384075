body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}

.login-box {
  background: rgba(255, 255, 255, 0.2);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.error {
  color: rgb(188, 37, 37);
}

.login-header {
  margin-bottom: 20px;
}

.user-icon {
  width: 80px;
  height: 80px;
  color: var(--text-color);
}

.login-input {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--input-bg-color);
}

.login-input input::placeholder {
  color: rgba(255, 255, 255, 0.685);
}

.login-input .icon {
  margin-right: 10px;
  color: var(--text-color);
}

.login-input input {
  background: none;
  border: none;
  outline: none;
  color: var(--input-text-color);
  width: 100%;
}

.login-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.login-options label {
  display: flex;
  align-items: center;
  color: var(--label-color);
}

.login-options .forgot-password {
  color: var(--label-color);
  text-decoration: none;
}

.login-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: var(--button-bg-color);
  color: var(--text-color);
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
}

.login-button:hover {
  background-color: var(--button-hover-bg-color);
}

@media (max-width: 768px) {
  .login-container {
    padding: 20px; 
    display: block;
    width: 100vw;
    margin: 0;
  }

  .login-box {
    padding: 20px; /* Mobilde daha küçük padding */
    box-shadow: none; /* Mobilde gölge kaldırıldı */
  }

  .login-input {
    padding: 8px; /* Mobilde daha küçük padding */
  }

  .login-button {
    padding: 8px; /* Mobilde daha küçük padding */
    font-size: 14px; /* Mobilde daha küçük font boyutu */
  }
}
