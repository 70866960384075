:root {
    --background-gradient-start: #00ff1eb7;
    --background-gradient-end: #bfff00c0;
    --primary-color: #333333;
    --primary-hover-color: #555555;
    --text-color: black;
    --input-bg-color: rgba(255, 255, 255, 0.9);
    --input-text-color: black;
    --input-placeholder-color: rgb(114, 97, 97);
    --label-color: black;
    --button-bg-color: #333333;
    --button-hover-bg-color: #555555;
    --alert-bg-color: #f2f2f2;
    --alert-text-color: #333333;
    --error-bg-color: #f44336;
    --error-text-color: white;
  }
  
  body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  body {
    background: linear-gradient(135deg, var(--background-gradient-start), var(--background-gradient-end)) !important;
  }
  
  .App {
    display: flex;
    min-height: 80vh;
    margin-top: 50px;
    color: var(--text-color);
    flex-direction: column;
  }
  
  .content {
    flex: 1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
  }
  
  .home, .about, .fields, .contact {
    padding: 20px;
    color: var(--text-color);
  }
  