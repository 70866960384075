.admin-panel {
  --background-gradient-start: #31b528;
  --background-gradient-end: #0d71d4;
  --sidebar-bg: rgba(0, 0, 0, 0.8);
  --primary-color: #7ed2ff;
  --primary-hover-color: #051ca3;
  --text-color: white;
  --input-bg-color: rgba(255, 255, 255, 0.1);
  --input-text-color: white;
  --input-placeholder-color: rgb(114, 97, 97);
  --label-color: white;
  --button-bg-color: #051ca3;
  --button-hover-bg-color: rgb(23, 183, 211);
  --edit-button-bg-color: #4CAF50;
  --edit-button-hover-bg-color: #45a049;
  --delete-button-bg-color: #f44336;
  --delete-button-hover-bg-color: #e53935;
  --alert-bg-color: #ffcccc;
  --alert-text-color: #cc0000;
  --error-bg-color: #f44336;
  --error-text-color: white;
}

.admin-panel, .admin-panel {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.admin-panel {
  display: flex;
  min-height: 100vh;
  color: var(--text-color);
  flex-direction: column;
  background: linear-gradient(135deg, var(--background-gradient-start), var(--background-gradient-end)) !important;
}

.admin-panel .content {
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
}

@media (min-width: 769px) {
  .admin-panel {
    margin-left: 250px;
  }
}


@media (max-width: 768px) {
  .admin-panel {
    padding: 0;
    flex-direction: row;
  }
}
