.field-owner-dashboard {
  padding: 20px;
  max-width: 100%; /* Tam genişlikte kalması için ayarlandı */
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  color: #ffffff;
}

.field-owner-dashboard h2 {
  text-align: center;
  margin-bottom: 20px;
}

.field-owner-dashboard h3 {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #ff7b00;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #e0e0e0;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #2e2e2e;
  color: #ffffff;
}

.submit-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #ff7b00;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #ffab40;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  overflow-x: auto; /* Tablo taşmalarını önlemek için eklendi */
}

.users-table th,
.users-table td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: center;
}

.users-table th {
  background-color: #ff7b00;
  color: white;
}

.users-table td {
  background-color: #2e2e2e;
}

.edit-button,
.delete-button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.edit-button {
  background-color: #ff7b00;
  color: white;
}

.delete-button {
  background-color: #e53935;
  color: white;
}

.edit-button:hover {
  background-color: #ffab40;
}

.delete-button:hover {
  background-color: #ff6b6b;
}

@media (max-width: 768px) {
  .field-owner-dashboard {
    padding: 10px;
    margin: 10px;
  }

  .field-owner-dashboard h2,
  .field-owner-dashboard h3 {
    font-size: 18px; /* Font boyutu küçültüldü */
  }

  .form-group input {
    padding: 6px; /* Padding küçültüldü */
  }

  .submit-button {
    padding: 8px;
    font-size: 14px; /* Font boyutu küçültüldü */
  }

  .users-table th,
  .users-table td {
    font-size: 12px; /* Font boyutu küçültüldü */
    padding: 4px;
  }

  .users-table th {
    font-size: 14px; /* Font boyutu küçültüldü */
  }

  .edit-button,
  .delete-button {
    padding: 4px 8px;
    font-size: 12px; /* Font boyutu küçültüldü */
  }
}
