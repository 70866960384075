.admin-field-schedule {
  padding: 20px;
  margin: 0 auto;
  background-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  max-width: 100%;
}

.admin-field-schedule h2 {
  text-align: center;
  color: #ff7b00;
  margin-bottom: 20px;
}

.admin-date-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.admin-date-controls button {
  background: none;
  border: none;
  color: #ff7b00;
  font-size: 24px;
  cursor: pointer;
  margin: 0 10px;
}

.admin-schedule-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  overflow-x: auto;
}

.admin-schedule-table th,
.admin-schedule-table td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: center;
}

.admin-schedule-table th {
  background-color: #ff7b00;
  color: white;
}

.admin-schedule-table td {
  background-color: #2e2e2e;
}

.admin-hour-cell {
  min-height: 50px;
  min-width: 100px; /* Decreased min-width for mobile compatibility */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .admin-field-schedule {
    padding: 10px;
    overflow: auto;
    margin: 10px;
  }

  .admin-date-controls button {
    font-size: 20px;
    margin: 5px 0;
  }
  .admin-schedule-table{
    overflow: auto;
    max-width: 100vw;
  }
  .admin-schedule-table th,
  .admin-schedule-table td {
    padding: 4px;
    font-size: 11px; /* Reduced font size for mobile */
  }

  .admin-hour-cell {
    min-height: 30px; /* Reduced min-height for mobile */
    min-width: 10px;  /* Reduced min-width for mobile */
  }

}
