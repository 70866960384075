.fields {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fields h1 {
  margin-bottom: 20px;
}

.fields p {
  max-width: 600px;
}

.filter {
  margin-bottom: 20px;
}

.filter select {
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 4px;
  min-width: 200px;
}

.field-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 90vw;
}
