.home {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
}

.search-bar input {
  width: 60%;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.field-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 90vw;
}

@media (max-width: 768px) {
  .search-bar input {
    width: 80%;
  }

  .field-list {
    width: 90vw;
  }
}

@media (max-width: 480px) {
  .search-bar input {
    width: 100%;
  }

  .field-list {
    width: 100%;
  }
}
