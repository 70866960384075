.sidebar {
  width: 250px;
  background: var(--sidebar-bg);
  color: var(--text-color);
  height: 100vh;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
}

.sidebar h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5em;
  letter-spacing: 1px;
  color: var(--primary-color);
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  padding: 15px 10px;
  font-size: 1.1em;
}

.sidebar ul li a {
  color: var(--text-color);
  text-decoration: none;
  display: flex;
  align-items: center;
}

.sidebar ul li a:hover {
  background: var(--primary-hover-color);
  border-radius: 5px;
  padding-left: 5px;
}

.sidebar ul li a svg {
  margin-right: 10px;
}

.sidebar ul li a.logout {
  color: red;
}

.sidebar ul li a.logout:hover {
  background: var(--alert-bg-color);
  color: var(--alert-text-color);
}

.home-link {
  text-decoration: none;
}

.welcome-text {
  font-size: 14px;
  color: #ffffff;
  margin: 0 16px;
  font-family: 'Arial', sans-serif;
}

.sidebar-toggle {
  display: none;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;
  background: var(--sidebar-bg);
  color: var(--text-color);
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%);
    z-index: 999;
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .sidebar-toggle {
    display: block;
  }
}
