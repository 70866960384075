.about {
  padding: 40px 20px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  background-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  color: #ffffff;
}

.about h1 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #ff7b00;
}

.mission-vision {
  text-align: left;
  margin-top: 20px;
}

.mission-vision h2 {
  font-size: 28px;
  margin-top: 20px;
  color: #ff7b00;
}

.mission-vision p {
  font-size: 18px;
  color: #e0e0e0;
  line-height: 1.6;
  margin-top: 10px;
}
