.contact {
  display: flex;
  justify-content: space-between;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.contact-info {
  width: 45%;
}

.contact h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.contact p, .contact address {
  font-size: 18px;
  line-height: 1.6;
  margin-top: 10px;
}

.contact a {
  color: #ff7b00;
  text-decoration: none;
}

.contact a:hover {
  text-decoration: underline;
}

.social-media {
  margin-top: 20px;
}

.social-media a {
  color: #ff7b00;
  font-size: 24px;
  margin-right: 10px;
  transition: color 0.3s ease;
}

.social-media a:hover {
  color: #ffab40;
}

.contact-form {
  width: 45%;
}

.form-group {
  margin-bottom: 15px;
}

.form-group input, .form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #ffffff;
}

.form-group input::placeholder, .form-group textarea::placeholder {
  color: #bbbbbb;
}

button[type="submit"] {
  padding: 10px 20px;
  background-color: #ff7b00;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #ffab40;
}
