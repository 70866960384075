.provider-form-container {
  margin: 0 auto;
  border-radius: 8px;
  color: var(--text-color);
  background-color: rgba(24, 24, 24, 0.208);
  padding: 20px;
  max-width: 100%; /* Tam genişlikte kalması için ayarlandı */
}

.provider-form-container h2,
.provider-form-container h3 {
  text-align: center;
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
}

.form-group {
  flex: 1 1 calc(50% - 10px); /* Daha fazla yer açmak için genişlik arttırıldı */
  margin: 5px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: var(--label-color);
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  background: var(--input-bg-color) !important;
  color: var(--input-text-color);
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.form-group input::placeholder {
  color: var(--input-placeholder-color);
}

.hours-container {
  display: flex;
  align-items: center;
}

.hours-container input {
  width: calc(50% - 10px); /* Genişlik küçültüldü */
  margin: 0 5px;
}

.switch-group {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 5px;
}

.switch-group label {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.add-field-button,
.submit-button,
.delete-button,
.edit-button,
.save-button,
.cancel-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--button-bg-color);
  color: white;
  cursor: pointer;
  margin-top: 10px;
}

.save-button {
  background-color: orange;
}

.delete-button {
  background-color: var(--delete-button-bg-color);
}

.submit-button:hover {
  background-color: var(--button-hover-bg-color);
}

.delete-button:hover {
  background-color: var(--delete-button-hover-bg-color);
}

.fields-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  table-layout: auto;
  background-color: #f8f8f8;
  border-radius: 8px;
  overflow: hidden;
}

.fields-table th,
.fields-table td {
  padding: 8px; /* Padding küçültüldü */
  border: 1px solid #ddd;
  text-align: center;
  vertical-align: middle;
}

.fields-table th {
  background-color: #f2a654;
  color: white;
}

.fields-table td {
  background-color: #333;
  color: #fff;
}

.form-group input,
.form-group select,
.fields-table input {
  background: #555 !important;
  color: #fff;
}

.image-thumbnails {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.thumbnail {
  position: relative;
  margin: 5px;
}

.thumbnail img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
  border: 2px solid #ddd;
}

.Modal {
  background: rgb(0, 0, 0);
  padding: 50px;
  border-radius: 10px;
  max-width: 500px;
  margin: auto;
  color: white;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.modal-content .form-group {
  margin-bottom: 20px;
}

/* Mobil ve tablet cihazlar için medya sorguları */
@media (max-width: 768px) {
  .provider-form-container {
    padding: 10px;
    margin: 0;
  }

  h2{
    font-size: 17px !important;
  }
  .form-row {
    flex-direction: column;
    align-items: stretch;
  }

  .form-group {
    flex: 1 1 100%;
    margin: 5px 0;
    margin-bottom: 0;
  }

  .hours-container input {
    width: calc(50% - 10px);
  }
  .hours-container{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
  }
  .switch-group {
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin: 0;
    max-width: 50%;
  }

  .switch-group label {
    margin-bottom: 10px;
  }

  .fields-table {
    width: 100%;
    min-width: 100vw;
    overflow-x:scroll;
  }

  .fields-table th,
  .fields-table td {
    font-size: 10px;
    padding: 0;
    margin: 0;
  }


  .fields-table td input{
    max-width: 40px;
  }

  .fields-table th {
    font-size: 14px;
    padding: 0;
  }

  .image-thumbnails {
    justify-content: flex-start;
  }

  .thumbnail img {
    width: 50px;
    height: 50px;
  }

  .add-field-button,
  .submit-button,
  .delete-button,
  .edit-button,
  .save-button,
  .cancel-button {
    padding: 8px;
    font-size: 14px;
  }

  .Modal {
    padding: 20px;
    max-width: 90%;
  }

}
