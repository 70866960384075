.field-card {
    display: flex;
    flex-direction: row;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    background: #fff;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    width: 100%;
  }
  
  .field-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .field-images {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin-right: 20px;
  }
  
  .field-main-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .field-thumbnails {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .field-thumbnails img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 4px;
  }
  
  .field-thumbnails img.selected {
    border: 2px solid #007bff;
  }
  
  .field-details {
    display: flex;
    flex-direction: column;
    width: 30%;
  }
  
  .field-info {
    margin-bottom: 20px;
  }
  
  .field-info h2 {
    margin-top: 0;
  }
  
  .field-info p {
    margin: 5px 0;
  }
  
  .field-features {
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 10px;
  }
  
  .field-features h3 {
    margin-bottom: 10px;
  }
  
  .field-features p {
    display: flex;
    gap: 10px;
    margin: 5px 0;
  }
  
  .field-contact {
    margin-bottom: 20px;
    padding: 10px;
  }
  
  .field-contact h3 {
    margin-bottom: 10px;
  }
  
  .field-contact p {
    margin: 5px 0;
  }
  
  .field-address p {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
  }

  
  @media (max-width: 768px) {
    .field-card {
      flex-direction: column;
    }
    
    .field-images {
      width: 100%;
      margin-right: 0;
    }
  
    .field-details {
      width: 100%;
      margin-right: 0;
    }
  }