.footer {
  background-color: #2d2d2d;
  color: #fff;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  bottom: 0;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.footer-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.footer-logo img {
  height: 50px;
  margin-bottom: 10px;
}

.footer-logo p {
  max-width: 200px;
  text-align: center;
  font-size: 14px;
}

.footer-links {
  display: flex;
  justify-content: center;
  width: 100%;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;
}

.footer-links ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links ul li a {
  color: #fff;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.footer-icon {
  font-size: 24px;
}

.footer-text {
  font-size: 14px;
  display: none;
}

.footer-bottom {
  margin-top: 20px;
  text-align: center;
}

@media (min-width: 768px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
  }
  .footer-links ul {
    gap: 40px;
  }
  .footer-text {
    display: inline;
  }
}
